/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"

export const onRouteUpdate = () => {
    var loaderElement = document.getElementById("___loader");
    if ( process.env.ENABLE_PRELOADER === `true` && loaderElement ) {
        console.log("Hidding preloader")
        setTimeout(function() {
            loaderElement.classList.add("hidden")
            setTimeout(function() {
                loaderElement.style.display = "none"
            }, 1000)
        }, 1000)
    }
}